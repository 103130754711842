<template>
  <ValidationObserver ref="observer">
    <EKDialog
      ref="notificationDialog"
      :title="!isEdit ? 'إرسال إشعار' : 'تفاصيل الإشعار'"
      :placeholder="!isEdit ? 'ابحث عن إشعار ما...' : ''"
      :btnText="!isEdit ? 'إرسال إشعار' : ''"
      @close="$store.commit('Reset_Notification_Dto')"
      :isEdit="isEdit"
      @search="search"
      @ok="onSubmit"
    >
      <template #body>
        <div v-if="!isEdit">
          <label>إرسال إلى</label>
          <div class="d-flex align-items-center my-50">
            <label class="m-0">الطلاب</label>
            <b-checkbox switch v-model="notificationDto.notificationType"></b-checkbox>
            <label class="m-0">نقاط البيع</label>
          </div>
        </div>
        <label class="mb-50">فلترة حسب : </label>
        <EKInputSelect
          v-if="!notificationDto.notificationType"
          label="كلية"
          placeholder="اختر الكلية"
          @change="onChange($event)"
          :options="faculties"
          name="selectfactely"
          :clearable="true"
          v-model="notificationFilterDto.faculityId"
        />
        <EKInputSelect
          v-else
          label="المدينة"
          placeholder="اختر المدينة"
          :options="citiesList"
          name="selectciies"
          v-model="notificationFilterDto.cityId"
          :clearable="true"
        />
        <template>
          <label>إرسال إلى</label>
          <div class="d-flex align-items-center my-50">
            <b-form-checkbox
              v-if="notificationDto.notificationType"
              v-model="allSelected"
              @change="gettAll"
              >جميع نقاط البيع
            </b-form-checkbox>
            <b-form-checkbox
              v-if="!notificationDto.notificationType"
              v-model="allSelected"
              @change="gettAll"
              >جميع الطلاب
            </b-form-checkbox>
            <!-- <b-checkbox v-model="type"></b-checkbox>
            <label class="m-0">{{
              notificationDto.notificationType ? "جميع نقاط البيع" : "جميع الطلاب"
            }}</label> -->
          </div>
        </template>
        <EKInputSelect
          v-if="!notificationDto.notificationType"
          label="الطالب"
          placeholder="اختر الطالب"
          name="selectstudent"
          :options="usersList"
          multiple
          v-model="notificationDto.userIds"
          :clearable="true"
        />
        <EKInputSelect
          v-if="notificationDto.notificationType"
          label="نقطة البيع"
          placeholder="اختر نقطة البيع"
          name="selectsell"
          multiple
          :options="usersList"
          v-model="notificationDto.userIds"
          :clearable="true"
        />

        <label
          class="text-danger"
          v-if="
            notificationDto.userIds.length == 0 &&
            filterdUserListForNotification.length &&
            !type
          "
        >
          {{
            notificationDto.notificationType
              ? "الرجاء اختيار نقطة بيع محددة"
              : "الرجاء اختيار طلاب محددين"
          }}
        </label>
        <hr />
        <EKInputText
          :rules="[{ type: 'required', message: ' العنوان مطلوب' }]"
          label="عنوان الإشعار"
          v-model="notificationDto.title"
          placeholder="ادخل العنوان"
          name="title"
        />
        <EKInputTextarea
          :rules="[{ type: 'required', message: ' النص إجباري' }]"
          label="نص الإشعار"
          v-model="notificationDto.body"
          placeholder="ادخل النص"
          name="body"
        />
      </template>
      <template #footer>
        <slot name="footer">
          <div class="d-flex align-items-center px-1 py-1 border-top">
            <b-button
              variant="outline-danger"
              class="mr-auto"
              v-if="isEdit"
              @click="deleteNotification(notificationDto.id)"
              >حذف</b-button
            >
            <b-button variant="outline-secondary" @click="close()">إلغاء</b-button>
            <b-button variant="primary" class="ml-auto" v-if="!isEdit" @click="onSubmit"
              >حفظ</b-button
            >
            {{ type }}
          </div>
        </slot>
      </template>
    </EKDialog>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  props: {
    isEdit: Boolean,
  },
  components: {
    ValidationObserver,
    EKDialog,
    EKInputText,
    EKInputSelect,
    EKInputTextarea,
  },
  data() {
    return {
      currentState: false,
      type: false,
      allSelected: false,
    };
  },
  computed: {
    ...mapGetters(["filterdUserListForNotification", "tagsList", "semester", "doctors"]),
    ...mapState({
      notificationDto: (state) => state.natification.notificationDto,
      notificationFilterDto: (state) => state.natification.notificationFilterDto,
      faculties: (state) => state.faculties.faculties,
      citiesList: (state) => state.globalStore.citiesList,
      usersList: (state) => state.accounts.usersList,
    }),
  },
  created() {
    this.getFacultiesDetails();
    this.fetchCity();
    this.fetchTotalTag();
    this.getUsers(0);
  },
  methods: {
    ...mapActions([
      "deleteNotification",
      "newNotification",
      "getFacultiesDetails",
      "fetchCity",
      "fetchTotalTag",
      "getUsers",
    ]),
    onChange(faculityId) {
      console.log(faculityId);
      this.getUsers(faculityId);
    },
    gettAll(checked) {
      this.selecte = checked ? this.usersList.slice() : [];
      this.notificationDto.userIds = this.selecte.map((el) => el.id);
    },
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success && this.notificationDto.userIds.length && !this.type) {
          this.newNotification({
            title: this.notificationDto.title,
            body: this.notificationDto.body,
            date: this.notificationDto.date,
            notificationType: +this.notificationDto.notificationType,
            userIds: this.notificationDto.userIds,
          });
        } else if (success && this.type) {
          this.newNotification({
            title: this.notificationDto.title,
            body: this.notificationDto.body,
            date: this.notificationDto.date,
            notificationType: +this.notificationDto.notificationType,
            userIds: [],
          });
        }
      });
    },
    open() {
      this.$refs.notificationDialog.open();
    },
    close() {
      this.$refs.notificationDialog.close();
    },
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["title", "body"],
        query,
      });
    },
  },
  watch: {
    "notificationDto.notificationType"(val) {
      this.notificationFilterDto.faculityId = 0;
      this.notificationFilterDto.cityId = 0;
      this.notificationDto.userIds = [];
      this.getUsers({ type: +val });
    },
    "notificationFilterDto.faculityId"(fid) {
      console.log(fid);
      this.notificationDto.userIds = [];
      this.getUsers({ type: 0, faculityId: fid });
    },
    "notificationFilterDto.cityId"(cid) {
      console.log(cid);
      this.notificationDto.userIds = [];
      this.getUsers({ type: 1, cityId: cid });
    },
    type() {
      if (this.type) {
        this.notificationDto.userIds = this.filterdUserListForNotification.map(
          (n) => n.id
        );
      } else {
        this.notificationDto.userIds = [];
      }
    },
  },
};
</script>
